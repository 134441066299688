<template>
  <el-form ref="form" :model="form" :rules="rules">
    <el-form-item prop="oldPassword">
      <el-input
        v-model="form.oldPassword"
        type="password"
        placeholder="请输入旧密码"
      ></el-input>
    </el-form-item>
    <el-form-item prop="newPassword">
      <el-input
        v-model="form.newPassword"
        type="password"
        placeholder="请输入新密码"
      ></el-input>
    </el-form-item>
    <el-form-item prop="confirmPassword">
      <el-input
        v-model="form.confirmPassword"
        type="password"
        placeholder="请再次输入密码"
      ></el-input>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" class="submit-button" @click="submit"
        >确认修改</el-button
      >
    </el-form-item>
  </el-form>
</template>

<script>
import { validPassword } from '@/utils/validate'
import { ElMessageBox } from 'element-plus'
import { setPassword } from '../../../api/user'

export default {
  name: 'ResetPasswordByEmail',
  data() {
    return {
      form: {
        oldPassword: null,
        newPassword: null,
        confirmPassword: null
      },
      rules: {
        oldPassword: [
          { required: true, trigger: 'blur', validator: this.validPassword }
        ],
        confirmPassword: [
          {
            required: true,
            trigger: 'blur',
            validator: this.validConfirmPassword
          }
        ]
      }
    }
  },

  methods: {
    validPassword(rule, value, callback) {
      if (!validPassword(value)) {
        callback(new Error('密码应包含字母和数字，长度8到20位'))
      } else {
        callback()
      }
    },

    validConfirmPassword(rule, value, callback) {
      if (value !== this.form.newPassword) {
        callback(new Error('密码不一致'))
      } else {
        callback()
      }
    },

    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const parms = {
            id: this.$store.state.userInfo.id,
            new: this.form.oldPassword,
            old: this.form.newPassword
          }
          setPassword(parms).then(() => {
            ElMessageBox.confirm(
              '你的登录密码已重置，请在客户端重新登录',
              '提示',
              {
                type: 'success',
                confirmButtonText: '确定',
                cancelButtonText: '取消'
              }
            ).then(() => {
              console.log('跳转下载链接')
            })
          })
        }
      })
    }
  }
}
</script>

<style scoped>
.submit-button {
  width: 100%;
}
</style>
