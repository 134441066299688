<template>
  <div class="reset">
    <el-card>
      <template #header>
        <h3>
          {{ rewardPassword ? '修改密码' : '你可以通过以下方式找回密码' }}
        </h3>
        <!-- <el-radio-group v-model="isEmail">
          <el-radio-button :label="true">邮箱</el-radio-button>
          <el-radio-button :label="false">手机</el-radio-button>
        </el-radio-group> -->
      </template>
      <reset-password-by-email v-if="isEmail"></reset-password-by-email>
      <reward-password v-else-if="rewardPassword"></reward-password>
      <reset-password-by-mobile v-else></reset-password-by-mobile>
    </el-card>
  </div>
</template>

<script>
import ResetPasswordByEmail from './components/ResetPasswordByEmail'
import ResetPasswordByMobile from './components/ResetPasswordByMobile'
import RewardPassword from './components/rewardPassword'

export default {
  name: 'index',
  components: {
    ResetPasswordByEmail,
    ResetPasswordByMobile,
    RewardPassword
  },

  data() {
    return {
      isEmail: false,
      rewardPassword: false
    }
  },

  mounted() {
    if (Object.keys(this.$store.state.userInfo).length) {
      this.rewardPassword = true
    }
  }
}
</script>

<style lang="scss" scoped>
.reset {
  height: 100vh;
  text-align: center;

  .el-card {
    width: 600px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: left;
  }
}
</style>
