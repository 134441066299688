<template>
  <el-form ref="form" :model="form" :rules="rules">
    <el-form-item prop="email">
      <el-input v-model="form.email" placeholder="邮箱"></el-input>
    </el-form-item>
    <el-form-item prop="captcha">
      <el-input v-model="form.captcha" placeholder="验证码">
        <template #append>
          <el-button :loading="loading" @click="getCaptcha">{{
            captchaStatus
          }}</el-button>
        </template>
      </el-input>
    </el-form-item>
    <el-form-item prop="password">
      <el-input
        v-model="form.password"
        type="password"
        placeholder="密码"
      ></el-input>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" class="submit-button" @click="submit"
        >重置</el-button
      >
    </el-form-item>
  </el-form>
</template>

<script>
import { validCaptcha, validEmail, validPassword } from '@/utils/validate'
import { ElMessage, ElMessageBox } from 'element-plus'
import { getEmailCaptcha } from '@/api/verif'
import { resetPasswordByEmail } from '@/api/auth'

export default {
  name: 'ResetPasswordByEmail',
  data() {
    return {
      form: {
        email: null,
        captcha: null,
        password: null
      },
      loading: false,
      rules: {
        email: [
          { required: true, trigger: 'blur', validator: this.validEmail }
        ],
        captcha: [
          { required: true, trigger: 'blur', validator: this.validCaptcha }
        ],
        password: [
          { required: true, trigger: 'blur', validator: this.validPassword }
        ]
      },
      captchaStatus: '获取验证码'
    }
  },
  methods: {
    validEmail(rule, value, callback) {
      if (!validEmail(value)) {
        callback(new Error('邮箱格式错误'))
      } else {
        callback()
      }
    },
    validCaptcha(rule, value, callback) {
      if (!validCaptcha(value)) {
        callback(new Error('无效的验证码'))
      } else {
        callback()
      }
    },
    validPassword(rule, value, callback) {
      if (!validPassword(value)) {
        callback(new Error('密码应包含字母和数字，长度8到20位'))
      } else {
        callback()
      }
    },
    getCaptcha() {
      if (!this.form.email) {
        ElMessage.error('请输入电子邮箱账号')
      } else {
        let time = 60
        let timer = setInterval(() => {
          this.captchaStatus = time + 's'
          time--
          if (time <= 0) {
            timer = null
            this.captchaStatus = '重新获取'
          }
        }, 1000)
        this.loading = true
        getEmailCaptcha({ email: this.form.email })
          .then((resp) => {
            if (resp.data) {
              ElMessage({
                type: 'success',
                message: '验证码已发送到您的邮箱，请注意查收'
              })
              this.loading = false
            }
          })
          .catch(() => {
            this.loading = false
          })
      }
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          resetPasswordByEmail(this.form).then(() => {
            ElMessageBox.confirm(
              '你的登录密码已重置，请在客户端重新登录',
              '提示',
              {
                type: 'success',
                confirmButtonText: '确定',
                cancelButtonText: '取消'
              }
            ).then(() => {
              console.log('跳转下载链接')
            })
          })
        }
      })
    }
  }
}
</script>

<style scoped>
.submit-button {
  width: 100%;
}
</style>
